




















































import { Vue, Component, Prop } from "vue-property-decorator";
import { ProfileProperties } from "./ProfileView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { EditorControlProperties } from "@/components/shared/EditorControlView.vue";
import { ProfileAddressEditorProperties } from "./ProfileAddressEditorView.vue";
import EditorControlView from "@/components/shared/EditorControlView.vue";
import ProfileAddressService from "@/services/ProfileAddressService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import AddressUtil from "@/utilities/AddressUtil";
import CommonUtil from "@/utilities/CommonUtil";

const ProfileAddressEditorView = () => import("./ProfileAddressEditorView.vue");

@Component({
    components: { EditorControlView, ProfileAddressEditorView },
    data: () => ({
        editing: false,
        addresses: []
    })
})
export default class ProfileAddressView extends Vue {
    @Prop() private properties: ProfileProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private editorControl = new EditorControlProperties();
    private profileAddressEditor = new ProfileAddressEditorProperties();
    private profileAddressService = new ProfileAddressService();

    public hashChanged(hash: string) {
        if (hash === "#address-new") {
            this.create();
        } else if (hash === "#address") {
            this.$data.editing = false;
        }
    }

    public created() {
        this.editorControl.cancelVisible = false;
        this.editorControl.saveVisible = false;
        this.editorControl.deleteVisible = false;

        this.properties.events.subscribe("alert-dialog-clicked", this.alertDialogClicked);
        this.load();

        this.$router.beforeEach((to, from, next) => {
            this.hashChanged(to.hash);
            next();
        });

        this.hashChanged(this.$route.hash);
    }

    public destroyed() {
        this.properties.events.remove("alert-dialog-clicked", this.alertDialogClicked);
    }

    public async load() {
        try {
            const r = await this.profileAddressService.get({ 
				loadCity: true, 
				loadState: true, 
				loadCountry: true 
			});
            for (var i = 0; i < r.data.length; i++) {
                r.data[i].full = AddressUtil.build(r.data[i]);
            }
            this.$data.addresses = r.data.sort((lhs, rhs) => rhs.id - lhs.id);
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public create() {
        const address: any = { city: { state: { country: {} } } };

        for (var n = 1; ; n++) {
            var exists = false;
            var name = "Address " + n;
            for (var i = 0; i < this.$data.addresses.length; i++) {
                if (name === this.$data.addresses[i].name) {
                    exists = true;
                    break;
                }
            }

            if (!exists) {
                address.name = name;
                break;
            }
        }

        this.profileAddressEditor.isNew = true;
        this.profileAddressEditor.address = address;
        this.$data.editing = true;
    }

    public modify(record: any) {
        this.profileAddressEditor.isNew = false;
        this.profileAddressEditor.address = CommonUtil.clone(record);
        this.$data.editing = true;
    }

    public async remove(record: any) {
        try {
            await this.profileAddressService.delete(record);
            await this.load();
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public control(button: string) {
        if (button === "create") {
            this.create();
        }
    }

    public removeDialog(record: any) {
        AlertDialogProperties.delete(this.alertDialog, record.name);
        this.alertDialog.buttons = [this.$t("text.yes"), this.$t("text.no")];
        this.alertDialog.primaryButton = this.$t("text.yes");
        this.alertDialog.tag = { command: "remove", record };
        this.alertDialog.visible = true;
    }

    public editorBack(reload: boolean) {
        if (reload) this.load();
        this.$data.editing = false;
    }

    public async alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        if (tag && tag.command === "remove") {
            if (button === this.$t("text.yes")) {
                await this.remove(tag.record);
            }
        }
    }
}
