




















































































import { Vue, Component, Prop } from "vue-property-decorator";
import { ProfileProperties } from "./ProfileView.vue";
import { EditorControlProperties } from "@/components/shared/EditorControlView.vue";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import EditorControlView from "@/components/shared/EditorControlView.vue";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import CountryService from "@/services/CountryService";
import StateService from "@/services/StateService";
import CityService from "@/services/CityService";
import ProfileAddressService from "@/services/ProfileAddressService";

class ProfileAddressEditorProperties {
    isNew: boolean = false;
    address: any = { city: { state: { country: {} } } };
}

export { ProfileAddressEditorProperties };

@Component({
    components: { EditorControlView },
    data: () => ({
        saving: false,
        loadingCountries: false,
        loadingStates: false,
        loadingCities: false,
        states: [],
        countries: [],
        cities: [],
        countryId: null,
        stateId: null,
        cityId: null,
    }),
})
export default class ProfileAddressEditorView extends Vue {
    @Prop({ default: new ProfileAddressEditorProperties() })
    private properties: ProfileAddressEditorProperties;
    @Prop() private profile: ProfileProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private editorControl = new EditorControlProperties();
    private profileAddressService = new ProfileAddressService();
    private countryService = new CountryService();
    private stateService = new StateService();
    private cityService = new CityService();

    public created() {
        this.editorControl.createVisible = false;
        this.editorControl.deleteVisible = false;

        this.profile.events.subscribe("alert-dialog-closed", this.alertDialogClosed);

        this.load();
    }

    public destroyed() {
        this.profile.events.remove("alert-dialog-closed", this.alertDialogClosed);
    }

    public load() {
        const address = this.properties.address;
        this.$data.cityId = address.cityId;
        this.$data.stateId = address.city.stateId;
        this.$data.countryId = address.city.state.countryId;

        this.loadCountries();
        this.loadStates();
        this.loadCities();
    }

    public async loadCountries() {
        this.$data.loadingCountries = true;

        try {
            const r = await this.countryService.get();
            const countries = r.data.countries;
            this.$data.countries = countries.sort((lhs, rhs) => rhs.id - lhs.id);
        } catch (e) {
            this.$data.countries = [];
        } finally {
            this.$data.loadingCountries = false;
        }
    }

    public async loadStates() {
        this.$data.loadingStates = true;

        try {
            if (this.$data.countryId) {
                const r = await this.stateService.get({ countryId: this.$data.countryId });
                const states = r.data.states;
                this.$data.states = states.sort((lhs, rhs) => rhs.id - lhs.id);
            } else {
                this.$data.states = [];
            }
        } catch (e) {
            this.$data.states = [];
        } finally {
            this.$data.loadingStates = false;
        }
    }

    public async loadCities() {
        this.$data.loadingCities = true;

        try {
            if (this.$data.stateId) {
                const r = await this.cityService.get({ stateId: this.$data.stateId });
                const cities = r.data.cities;
                this.$data.cities = cities.sort((lhs, rhs) => rhs.id - lhs.id);
            } else {
                this.$data.cities = [];
            }
        } catch (e) {
            this.$data.cities = [];
        } finally {
            this.$data.loadingCities = false;
        }
    }

    public async save() {
        try {
            const address = this.properties.address;
            address.cityId = this.$data.cityId;

            if (!this.properties.isNew) {
                await this.profileAddressService.put(address);
            } else {
                await this.profileAddressService.post(address);
            }

            AlertDialogProperties.saved(this.alertDialog, null, null);
            this.alertDialog.tag = { command: "address-saved" };
            this.alertDialog.visible = true;
        } catch (e) {
            if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public countryChanged() {
        this.loadStates();
    }

    public stateChanged() {
        this.loadCities();
    }

    public async control(button: string) {
        if (this.$route.hash === "#address-new") {
            if (button === "cancel") {
                this.$router.push("/home");
            } else if (button === "save") {
                await this.save();
            }
        } else {
            if (button === "cancel") {
                this.$emit("back", false);
            } else if (button === "save") {
                await this.save();
            }
        }
    }

    public alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }

        const tag = this.alertDialog.tag;
        if (tag && tag.command === "address-saved") {
            if (this.$route.hash === "#address-new") {
                this.$router.push("/home");
            } else {
                this.$emit("back", true);
            }
        }
    }
}
